<!--
 * @Author: jiang
 * @Date: 2021-06-13 09:44:43
 * @Description: 待办详情
-->
<template>
  <div class="backlog-detail">
    <div class="header">
      <div>详情
        <el-tag
          v-if="forwardStatus === 1"
          type="warning"
        >任务已转发</el-tag>
      </div>
      <div>
        <el-button
          v-if="forwardStatus === 0"
          type="primary"
          @click="onForward"
        >转发</el-button>

        <el-button
          type="primary"
          size="mini"
          @click="onExport"
        >导出</el-button>
      </div>
    </div>
    <div
      class="body"
      v-loading="loading"
    >
      <el-scrollbar y>
        <div
          class="info-box"
          v-if="infoData.name && infoData.user"
        >
          <div class="content">{{ infoData.name }}</div>
          <el-row>
            <el-col
              :span="8"
              v-if="infoData.meeting"
            >
              <b-info-item
                label="所属任务目录分类"
                :value="infoData.meeting.name"
              ></b-info-item>
            </el-col>
            <el-col :span="8">
              <b-info-item
                label="创建人"
                :value="infoData.user.name"
              ></b-info-item>
            </el-col>
            <el-col :span="8">
              <b-info-item
                label="当前状态"
                :value="infoData.state"
              ></b-info-item>
            </el-col>
            <el-col :span="8">
              <b-info-item
                label="截止日期"
                :value="infoData.executive_end_data"
              ></b-info-item>
            </el-col>
          </el-row>

          <!-- 图片 -->
          <div
            class="image-box"
            v-if="infoData.images && infoData.images.length > 0"
          >
            <c-image-list :list="infoData.images"></c-image-list>
          </div>

          <!-- 附件 -->
          <div
            class="media-box"
            v-if="infoData.media && infoData.media.length > 0"
          >
            <CMediaListEdit :list="infoData.media"></CMediaListEdit>
          </div>

          <!-- 落实部门 -->
          <div class="dept-box">
            <el-tag
              class="dept-item"
              v-for="item in infoData.executive_departments"
              :key="item.id"
              :type="handlerGetType(item.id)"
            >{{ item.name}}</el-tag>
          </div>

          <!-- 落实人员 -->
          <div class="dept-box">
            <el-tag
              class="dept-item"
              v-for="item in infoData.executive_users_data"
              :key="item.id"
              :type="handlerGetType(item.id)"
            >{{ item.name}}</el-tag>
          </div>

        </div>

        <div class="g-p-20">
          <el-button
            v-if="type === 'backlog'"
            type="info"
            block
            @click="onAction"
          >添加我的落实情况</el-button>
        </div>
        <div class="action-box">
          <div
            class="action-item"
            v-for="(action, actionIndex) in actionList"
            :key="action.id"
          >

            <el-button
              class="update-btn"
              v-if="action.user_id === userId"
              type="primary"
              plain
              @click="onActionUpdate(action)"
            >修改</el-button>
            <!-- 落实标题 -->
            <div class="title" v-if="action.user.department">
              <span class="index">{{ actionIndex + 1 }}</span>
              <span>【{{ action.user.department.name }}】落实情况：</span>
            </div>
            <!-- 落实内容 -->
            <div class="content">{{ action.content }}</div>
            <!-- 落实图片 -->
            <div
              class="images"
              v-if="action.images && action.images.length > 0"
            >
              <c-image-list :list="action.images"></c-image-list>
            </div>
            <!-- 落实附件 -->
            <div
              class="media"
              v-if="action.media && action.media.length > 0"
            >
              <c-media-list :list="action.media"></c-media-list>
            </div>
            <!-- 落实其他信息 -->
            <div class="footer">由 {{ action.user.name }} {{ action.updated_at}} 填报</div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <b-dialog
      v-model="actionDialogVisible"
      width="700px"
      title="任务落实"
    >
      <action-view
        :data="actionData"
        :confirm-loading="confirmLoading"
        :is-media="infoData.medias_set === 1"
        @confirm="onActionConfirm"
      ></action-view>
    </b-dialog>

    <forward-view ref="forward"></forward-view>
  </div>
</template>

<script>
import CImageList from '@/components/upload/image-list'
import CMediaList from '@/components/upload/media-list'
import CMediaListEdit from '@/components/upload/media-list/edit.vue'
import ActionView from './components/action'
import ForwardView from './components/forward.vue'
import { getTaskDetail, exportTask } from '@/api/task'
import { createBacklogAction, updateBacklogAction } from '@/api/backlog'

export default {
  components: {
    CImageList,
    CMediaListEdit,
    CMediaList,
    ActionView,
    ForwardView,
  },
  data() {
    return {
      loading: false,
      id: '',
      backlogId: '',
      type: '',
      infoData: {},
      actionDialogVisible: false,
      actionData: {},
      confirmLoading: false,
    }
  },
  computed: {
    actionList() {
      const actions = []
      if (this.infoData.tasks) {
        this.infoData.tasks.forEach(item => {
          item.actions.forEach(it => {
            it.deptName = item.taskable.name
            actions.push(it)
          })
        })
      }
      return actions
    },
    userId() {
      return this.$store.getters['user/id']
    },
    forwardStatus() {
      if (this.type === 'backlog' && this.infoData.tasks) {
        const backlog = this.infoData.tasks.find(item => item.id === this.backlogId)
        if (backlog && backlog.taskable_type === 'App\\Models\\Department') {
          return backlog.forward_state
        }
      }
      return -1
    },
  },
  watch: {
    '$route.query.id': {
      handler(val) {
        if (val) {
          this.id = +val
          this.fetchData()
        }
      },
      immediate: true,
    },
    '$route.query.backlogId': {
      handler(backlogId) {
        if (backlogId) {
          this.backlogId = +backlogId
        }
      },
      immediate: true,
    },
    '$route.query.type': {
      handler(type) {
        if (type) {
          this.type = type
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchData() {
      this.loading = true
      getTaskDetail(this.id)
        .then(res => {
          this.infoData = res
        })
        .finally(() => {
          this.loading = false
        })
    },
    onAction() {
      this.actionData = {
        task_id: this.backlogId,
        content: '',
        images: [],
        media_keys: [],
      }
      this.actionDialogVisible = true
    },
    onActionUpdate(row) {
      this.actionData = {
        id: row.id,
        task_id: row.task_id,
        content: row.content,
        images: row.images,
        media_keys: [],
        media: row.media,
      }
      this.actionDialogVisible = true
    },
    onActionConfirm(data) {
      this.confirmLoading = true
      const params = {
        task_id: data.task_id,
        content: data.content,
        images: data.images,
        media_keys: data.media_keys,
      }
      let message

      Promise.resolve()
        .then(() => {
          if (data.id) {
            message = '落实修改成功'
            return updateBacklogAction(data.id, params)
          } else {
            message = '任务落实成功'
            return createBacklogAction(params)
          }
        })
        .then(() => {
          this.$message.success(message)
          this.fetchData()
          this.actionDialogVisible = false
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    onExport() {
      exportTask(this.id)
    },
    handlerGetType(id) {
      const task = this.infoData.tasks.find(item => item.taskable_id === id)
      return task.actions.length ? 'success' : 'danger'
    },
    onForward() {
      this.$refs.forward
        .open(this.backlogId)
        .then(res => {
          console.log('info', res)
          this.$store.commit('tag/REMOVE', this.$route.path)
        })
        .catch(err => {
          console.log('info', err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.backlog-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 15px 15px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: bold;
    color: #50526e;
    background: #e9ebef;
    border-radius: 8px 8px 0 0;
  }

  .body {
    flex: 1;
    min-height: 0;
    background-color: #fff;
  }

  .info-box {
    .content {
      padding: 10px;
      font-size: 14px;
      font-weight: bold;
      line-height: 30px;
      color: #2b2d42;
    }
  }

  .image-box {
    padding: 10px;
  }

  .media-box {
    padding: 10px;
  }

  .dept-box {
    padding: 10px;

    .dept-item {
      margin: 5px;
    }
  }

  .action-item {
    position: relative;
    padding: 10px;
    margin: 20px;
    background-color: #f8f9fa;
    border-radius: 4px;

    .update-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: #2b2d42;

      .index {
        padding: 0 10px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        background: #ffa940;
        border-radius: 15px 13px 13px 0;
      }
    }

    .content {
      margin: 10px 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #2b2d42;
    }

    .footer {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #626874;
    }
  }
}
</style>
